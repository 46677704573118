<template>
    <div class="site-menu__buttons" v-if="!$route.meta.hideButtons">
        <div class="site-menu__buttons__desktop">
            <template v-for="(item, index) in menuItems">
                <div v-if="item.type === 'ancor'" :key="`header-button-${index}`" class="site-menu__buttons__button" v-scroll-to="item.link">
                    {{ item.title }}
                </div>
                <div v-if="item.type === 'banquets' && site.style && site.style.params && site.style.params.banquets_title" :key="`header-button-${index}`" class="site-menu__buttons__button" v-scroll-to="item.link">
                    {{ site.style.params.banquets_title }}
                </div>
                <div v-if="hasFullMenu && item.type === 'menu'" :key="`header-button-${index}`" class="site-menu__buttons__button" @click="goToMenu">
                    {{ item.title }}
                </div>
                <div v-if="hasDelivery && item.type === 'delivery'" :key="`header-button-${index}`" class="site-menu__buttons__button" @click="goToDelivery">
                    {{ item.title }}
                </div>
            </template>
        </div>
        <div class="site-menu__buttons__mobile">
            <v-menu transition="slide-y-transition" :top="top" left offset-y hide-on-scroll>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-icon dark>mdi-menu</v-icon>
                    </div>
                </template>
                <v-list>
                    <template v-for="(item, index) in menuItems">
                        <v-list-item v-if="item.type === 'ancor'" :key="`header-mobile-${index}`" class="site-menu__buttons__mobile__item" v-scroll-to="item.link">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="item.type === 'banquets' && site.style && site.style.params && site.style.params.banquets_title" :key="`header-mobile-${index}`" class="site-menu__buttons__mobile__item" v-scroll-to="item.link">
                            <v-list-item-title>{{ site.style.params.banquets_title }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hasFullMenu && item.type === 'menu'" :key="`header-mobile-${index}`" class="site-menu__buttons__mobile__item" @click="goToMenu">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="hasDelivery && item.type === 'delivery'" :key="`header-mobile-${index}`" class="site-menu__buttons__mobile__item" @click="goToDelivery">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
    import { imageSrc } from '../../helpers';
    import { mapState } from 'vuex';

    export default {
        name: 'CategoriesMenu',
        props: {
            top: {
                type: Boolean,
                default: false
            },
            code: {
                type: String,
                default: ''
            },
        },
        computed: {
            ...mapState('sites', {
                site: state => state.entity
            }),
            hasFullMenu() {
                return (this.site.permitions && this.site.permitions.full_menu) || this.site.menu_file;
            },
            hasDelivery() {
                return (this.site.permitions && this.site.permitions.delivery) || this.site.delivery_url;
            },
        },
        data: () => ({
            menuItems: [
                { type: 'ancor', link: '#about', title: 'О ресторане' },
                { type: 'menu', link: '', title: 'Меню' },
                { type: 'delivery', link: '', title: 'Доставка' },
                { type: 'ancor', link: '#news', title: 'Акции' },
                { type: 'banquets', link: '#banquets', title: 'Банкеты' },
                { type: 'ancor', link: '#footer', title: 'Контакты' },
            ]
        }),
        methods: {
            async goToMenu() {
                const { code } = this.$route.params;
                if(this.site.permitions.full_menu) {
                    if(code) {
                        this.$router.push(`/${code}/menu`);
                    } else {
                        this.$router.push(`/menu`);
                    }
                } else if (this.site.menu_file) {
                    window.open(imageSrc(this.site.menu_file), '_blank');
                }
            },
            async goToDelivery() {
                const { code } = this.$route.params;
                if(this.site.permitions.delivery) {
                    if(code) {
                        this.$router.push(`/${code}/delivery`);
                    } else {
                        this.$router.push(`/delivery`);
                    }
                } else if (this.site.delivery_url) {
                    window.open(this.site.delivery_url, '_blank');
                }
            },
        }
    }
</script>

<style lang="scss">
.site-menu {
    &__buttons {
        &__button {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
            margin-left: 20px;
            white-space: nowrap;
            
            @media screen and (max-width: 1400px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        
        &__desktop {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width: 920px) {
                display: none;
            }
            
            > div {
                cursor: pointer;
                margin-left: 20px;
            }
        }

        &__mobile {
            display: none;

            @media screen and (max-width: 920px) {
                display: block;
            }

            &__item {
                cursor: pointer;
                min-height: 36px;
                
                .v-list-item__title {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }
}
</style>