<template>
    <div class="site-main" v-show="site && site.start_page === 'site'">

        <div :class="`site-main__header ${showTopMenu ? 'site-main__header__shown' : ''}`">
            <div class="site-main__header__content">
                <div class="site-main__header-block__content__logo">
                    <!-- <img v-if="logo" :src="logo">
                    <img v-else src="../../assets/site/logo.svg"> -->
                </div>
                <SiteMenu
                    :code="site.code" />
            </div>
        </div>

        <div class="site-main__header-block__wrapper">
            <div class="site-main__header-block">
                <div class="site-main__header-block__content">
                    <div class="site-main__header-block__content__top">
                        <div class="site-main__header-block__content__logo">
                            <img v-if="logo" :src="logo">
                            <img v-else src="../../assets/site/logo.svg">
                        </div>
                        <SiteMenu
                            :code="site.code" />
                    </div>
                    <div class="site-main__header-block__content__center">
                        <div class="site-main__header-block__content__title">{{ site.title }}</div>
                        <div class="site-main__header-block__content__preview" v-html="preview">
                        </div>
                        <div class="site-main__buttons site-main__header-block__content__buttons">
                            <div class="site-main__buttons__empty site-main__header-block__content__buttons__empty" v-if="hasDelivery" @click="goToDelivery">Заказать доставку</div>
                            <!-- <div class="site-main__buttons__fill site-main__header-block__content__buttons__fill">Забронировать столик</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="site-main__description-block">
            <div class="site-main__description-block__content">
                <div class="site-main__description-block__content__white-block">
                    <div class="site-main__ancor" id="about"></div>
                    <div class="site-main__description-block__content__text" v-html="detail"></div>
                    <div class="site-main__description-block__content__image">
                        <img v-if="site && site.style && site.style.params && site.style.params.detail_img" :src="imageSrc(site.style.params.detail_img)">
                        <img v-else src="../../assets/site/description-img.png">
                    </div>
                </div>
                <div class="site-main__description-block__content__galery" v-if="site && site.style && site.style.params && site.style.params.pictures">
                    <div>
                        <template v-for="(picture, index) in site.style.params.pictures">
                            <img :key="`galery-picture-${index}`" :src="imageSrc(picture)">
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="site-main__menu-block">
            <div class="site-main__menu-block__content">
                <div class="site-main__ancor" id="menu"></div>
                <div class="site-main__menu-block__content__list">
                    <template v-for="(product, index) in selectedProducts">
                        <div :key="`product-${index}`" v-if="product" class="site-main__menu-block__content__list__item">
                            <div v-if="product.picture" class="site-main__menu-block__content__list__item__image">
                                <img :src="imageSrc(product.picture)">
                            </div>
                            <div class="site-main__menu-block__content__list__item__title">
                                {{ product.title }}
                            </div>
                            <div class="site-main__menu-block__content__list__item__props">
                                <div class="site-main__menu-block__content__list__item__props__item">
                                    {{ product.weight ? weightFormated(product.weight) : '' }}
                                </div>
                                <!-- <div class="site-main__menu-block__content__list__item__props__item">
                                    {{ product.price ? priceFormated(product.price) : 'Бесплатно' }}
                                </div> -->
                            </div>
                        </div>
                    </template>
                </div>
                <div class="site-main__buttons site-main__menu-block__content__buttons">
                    <div class="site-main__buttons__fill site-main__menu-block__content__buttons__fill" v-if="hasFullMenu" @click="goToFullMenu">Смотреть меню</div>
                    <div class="site-main__buttons__empty site-main__menu-block__content__buttons__empty" v-if="hasDelivery" @click="goToDelivery">Заказать доставку</div>
                    <!-- <div class="site-main__buttons__fill site-main__menu-block__content__buttons__fill">Забронировать столик</div> -->
                </div>
            </div>
        </div>
        <div class="site-main__news-block" v-if="site && site.news && site.news.length">
            <div class="site-main__news-block__content">
                <div class="site-main__ancor" id="news"></div>
                <div class="site-main__news-block__content__title">
                    Акции
                </div>
                <div class="site-main__news-block__content__list">
                    <div>
                        <template v-for="(item, index) in site.news">
                            <div :key="`news-${index}`" class="site-main__news-block__content__list__item">
                                <div class="site-main__news-block__content__list__item__image">
                                    <img :src="imageSrc(item.picture)">
                                </div>
                                <div class="site-main__news-block__content__list__item__text">
                                    <div class="site-main__news-block__content__list__item__text__title">
                                        {{ item.title }}
                                    </div>
                                    <div v-html="htmlFormatted(item.preview)"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="site-main__buttons site-main__news-block__content__buttons">
                    <div class="site-main__buttons__empty site-main__news-block__content__buttons__empty" v-if="hasDelivery" @click="goToDelivery">Заказать доставку</div>
                    <!-- <div class="site-main__buttons__fill site-main__news-block__content__buttons__fill">Забронировать столик</div> -->
                </div>
            </div>
        </div>
        <div class="site-main__banquets-block" v-if="site && site.style && site.style.params && site.style.params.banquets_title">
            <div class="site-main__banquets-block__content">
                <div class="site-main__ancor" id="banquets"></div>
                <div class="site-main__banquets-block__content__block">
                    <div>
                        <div class="site-main__banquets-block__content__title">{{ site.style.params.banquets_title }}</div>
                        <div class="site-main__banquets-block__content__text" v-html="banquets_detail"></div>
                    </div>
                    <div class="site-main__banquets-block__content__image">
                        <img v-if="site && site.style && site.style.params && site.style.params.banquets_img" :src="imageSrc(site.style.params.banquets_img)">
                        <img v-else src="../../assets/site/description-img.png">
                    </div>
                </div>
                <div class="site-main__buttons site-main__banquets-block__content__buttons">
                    <div class="site-main__buttons__empty site-main__banquets-block__content__buttons__empty" @click="telLink">Заказать банкет</div>
                </div>
            </div>
        </div>
        <DeliveryZones
            v-if="site && site.entities && site.entities.length"
            :entities="site.entities"
            hide-zones />
        <div class="site-main__footer-block">
            <div class="site-main__ancor" id="footer"></div>
            <div class="site-main__footer-block__content">
                <div class="site-main__footer-block__content__logo">
                    <img v-if="logo" :src="logo">
                    <img v-else src="../../assets/site/logo-footer.svg">
                </div>
                <div class="site-main__footer-block__content__block">
                    <div class="site-main__footer-block__content__block__row">
                        <div class="site-main__footer-block__content__block__title">
                            {{ site.title }}
                        </div>
                        <SiteMenu 
                            :code="site.code" 
                            :top="true"
                        />
                    </div>
                    <div class="site-main__footer-block__content__block__entities">
                        <template v-for="entity in site.entities">
                            <div class="site-main__footer-block__content__block__entities__entity" :key="`footer-entity-${entity.id}`">
                                <div class="site-main__footer-block__content__block__entities__entity__address" v-if="entity.address">
                                    <a :href="`https://yandex.ru/maps/?pt=${entity.coordinates[0]},${entity.coordinates[1]}&z=12&l=map`" target="_blank">{{ entity.address }}</a>
                                </div>
                                <div class="site-main__footer-block__content__block__entities__entity__work_time" v-if="entity.work_time">
                                    {{ entity.work_time }}
                                </div>
                                <div class="site-main__footer-block__content__block__entities__entity__phone" v-if="entity.phone">
                                    <a :href="`tel:${entity.phone}`">{{ entity.phone }}</a>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { html2json } from 'html2json';
    import SiteMenu from '../../components/site/SiteMenu';
    import DeliveryZones from '../../components/site/DeliveryZones';
    import { imageSrc, htmlFormatted, weightFormated, priceFormated } from '../../helpers';

    export default {
        name: 'Site',
        metaInfo() {
            return {
                title: `${ this.site.title }`,
                meta: [
                    { vmid: 'description', name: 'description', content: this.site.description_seo }
                ],
            }
        },
        components: {
            SiteMenu,
            DeliveryZones
        },
        data: () => ({
            imageSrc,
            htmlFormatted,
            weightFormated, 
            priceFormated,
            showTopMenu: false
        }),
        computed: {
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('siteDiscounts', {
                discounts: state => state.entities
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            ...mapState('products', {
                products: state => state.entities
            }),
            logo() {
                return this.site && this.site.style && this.site.style.logo ? imageSrc(this.site.style.logo) : null;
            },
            preview() {
                return this.site && this.site.style && this.site.style.params && this.site.style.params.preview ? htmlFormatted(this.site.style.params.preview) : '';
            },
            detail() {
                return this.site && this.site.style && this.site.style.params && this.site.style.params.detail ? htmlFormatted(this.site.style.params.detail) : '';
            },
            banquets_detail() {
                return this.site && this.site.style && this.site.style.params && this.site.style.params.banquets_detail ? htmlFormatted(this.site.style.params.banquets_detail) : '';
            },
            addresses() {
                return this.site && this.site.entities ? this.site.entities.map(item => item.address).join('<br>') : '';
            },
            selectedProducts() {
                const products = [];
                if(this.products && this.site && this.site.style && this.site.style.params && this.site.style.params.products) {
                    for(const id of this.site.style.params.products) {
                        products.push(this.products.find(item => item.id === id));
                    }
                }
                return products;
            },
            hasDelivery() {
                return (this.site.permitions && this.site.permitions.delivery) || this.site.delivery_url;
            },
            hasFullMenu() {
                return (this.site.permitions && this.site.permitions.full_menu) || this.site.menu_file;
            }
        },
        async mounted() {
            const { code } = this.$route.params;
            if(code) {
                await store.dispatch('sites/getByCode', { code });
                switch(this.site.start_page) {
                    case 'delivery':
                        this.$router.replace(`/${code}/delivery`);
                        break;
                    case 'menu':
                        this.$router.replace(`/${code}/menu`);
                        break;
                }
            } else {
                await store.dispatch('sites/getByHost', { host: window.location.host });
                switch(this.site.start_page) {
                    case 'delivery':
                        this.$router.replace(`/delivery`);
                        break;
                    case 'menu':
                        this.$router.replace(`/menu`);
                        break;
                }
            }

            if(!this.site) {
                window.location.href = process.env.VUE_APP_FRONT_URL;
            }

            const style = document.documentElement.style;
            const siteStyle = this.site.style;

            const defaultScheme = ['#FCF1E4', '#A4C081', '#6F5E47', '#F2C94C', '#2C2422'];
            const scheme = this.site.style.params && this.site.style.params.scheme ? this.site.style.params.scheme : defaultScheme;
            let schemeIndex = 0;
            for(const color of scheme) {
                style.setProperty(`--site-scheme-color-${++schemeIndex}`, color);
            }

            await store.dispatch('categories/fetch', { site_id: this.site.id, filter: { for_delivery: true } });
            await store.dispatch('products/fetchBySite', { site_id: this.site.id, filter: {} });
            await store.dispatch('siteDiscounts/fetch', { filter: { site_id: this.site.id, for_delivery: true } });
            this.activeCategory = this.categories ? +this.categories[0].id : 0;
                
            // Default cart settings
            await store.dispatch('orders/fetchCart', { code: this.site.code });
            await store.dispatch('orders/setCartSite', this.site);
            // Default cart settings
            
            store.commit('orders/SET_CART_ORDER_CREATED', false);
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                if (window.innerWidth > 750) {
                    this.showTopMenu = (window.scrollY > 55);
                } else {
                    this.showTopMenu = (window.scrollY > 29);
                }
            },
            async goToDelivery() {
                const { code } = this.$route.params;
                if(this.site.permitions.delivery) {
                    if(code) {
                        this.$router.push(`/${code}/delivery`);
                    } else {
                        this.$router.push(`/delivery`);
                    }
                } else if (this.site.delivery_url) {
                    window.open(this.site.delivery_url, '_blank');
                }
            },
            async goToFullMenu() {
                const { code } = this.$route.params;
                if(this.site.permitions.full_menu) {
                    if(code) {
                        this.$router.push(`/${code}/menu`);
                    } else {
                        this.$router.push(`/menu`);
                    }
                } else if (this.site.menu_file) {
                    window.open(imageSrc(this.site.menu_file), '_blank');
                }
            },
            telLink() {
                if(this.site && this.site.entities && this.site.entities.length) {
                    window.location.href = `tel:${this.site.entities[0].phone}`;
                }
            }
        }
    }
</script>

<style lang="scss">
.site-main {
    font-family: 'Montserrat';

    &__ancor {
        position: absolute;
        top: -70px;
        left: 0;

        @media screen and (max-width: 750px) {
            top: -50px;
        }  
    }

    &__buttons {
        width: 100%;
        margin: 22px auto 0;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 830px) {
            width: 100%;
            max-width: 350px;
            flex-direction: column;
        }

        & > div {
            cursor: pointer;
            width: 350px;
            margin: 10px;
            border-radius: 18px;
            padding: 8px;
            font-size: 23px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;

            @media screen and (max-width: 750px) {
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                margin: 10px 0;
            }
        }

        &__empty {
            width: 100%;
            border: 3px solid var(--site-scheme-color-2);
            text-align: center;
            color: var(--site-scheme-color-2);
        }
        &__fill {
            width: 100%;
            background: var(--site-scheme-color-2);
            border: 3px solid var(--site-scheme-color-2);
            text-align: center;
            color: #FFFFFF;
        }
    } 

    &__header {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        padding: 0 40px;
        background-color: var(--site-scheme-color-5);
        opacity: 0;
        z-index: 10;  

        @media screen and (max-width: 750px) {
            padding: 0 20px;
        }  

        &__content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 1240px;
            margin: 0 auto;
            padding: 20px 35px;
            // transition: opacity .1s linear;

            @media screen and (max-width: 750px) {
                padding: 10px 19px;
            }
        }

        &__shown {
            opacity: 1;
            z-index: 1010;
        }
    }

    &__header-block {
        position: relative;
        width: 100%;
        padding: 40px 40px 0 40px;
        background: var(--main_site_top_bg) center center no-repeat;
        background-size: cover;
        z-index: 100;

        &__wrapper {
            width: 100%;
            background: url('../../assets/site/header-blur.png') center center no-repeat;
            background-size: cover;
        }

        @media screen and (max-width: 750px) {
            padding: 20px 20px 0 20px;
        }

        &__content {
            max-width: 1240px;
            margin: 0 auto;
            border: 3px solid #FFFFFF80;
            border-radius: 16px 16px 0 0;
            border-bottom: 0;
            padding: 32px 32px 150px;

            @media screen and (max-width: 750px) {
                padding: 16px 16px 150px;
            }

            &__top {
                display: flex;
                justify-content: space-between;
            }

            &__logo {
                & > img {
                    max-width: 100px;
                    max-height: 100px;
                }
            }
            &__menu {
                display: flex;
                justify-content: flex-end;

                &__item {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #FFFFFF;
                    margin-left: 20px;
                }
            }

            &__center {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 40px 0 0 0;
            }

            &__title {
                color: var(--site-scheme-color-2);
                font-size: 60px;
                font-weight: 700;
                line-height: 55px;
                letter-spacing: 0em;
                text-align: center;

                @media screen and (max-width: 600px) {
                    font-size: 44px;
                    line-height: 42px;
                }
            }
            &__preview {
                font-size: 40px;
                font-weight: 500;
                line-height: 49px;
                letter-spacing: 0em;
                text-align: center;
                color: #FFFFFF;
                margin-top: 32px;

                @media screen and (max-width: 750px) {
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 35px;
                }
            }
            &__buttons {
                width: 100%;
                max-width: 350px;
                margin-top: 22px;
                flex-direction: column;

                & > div {
                    margin: 10px 0;
                }
                &__empty {
                    color: #FFFFFF;
                }
            }
        }
    }

    &__description-block {
        position: relative;
        background: var(--site-scheme-color-1);
        padding: 0 40px;
        z-index: 110;

        @media screen and (max-width: 750px) {
            padding: 0 20px;
        }

        &__content {
            max-width: 1240px;
            margin: 0 auto;
            border: 3px solid #FFFFFF80;
            border-top: 0;
            border-bottom: 0;
            padding: 32px;

            @media screen and (max-width: 750px) {
                padding: 16px;
            }

            &__white-block {
                position: relative;
                max-width: 1000px;
                margin: -150px auto 0;
                display: flex;
                justify-content: space-between;
                padding: 32px;
                border-radius: 16px;
                background: #FFFFFF;

                @media screen and (max-width: 750px) {
                    padding: 16px;
                    flex-direction: column-reverse;
                }
            }
            &__text {
                width: 50%;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                color: var(--site-scheme-color-5);
                padding-right: 32px;

                @media screen and (max-width: 750px) {
                    width: 100%;
                    font-size: 14px;
                    line-height: 18px;
                    padding-top: 16px;
                    padding-right: 0;
                }
            }
            &__image {
                width: 50%;

                @media screen and (max-width: 750px) {
                    width: 100%;
                }

                & > img {
                    width: 100%;
                    border-radius: 16px;
                }
            }
            &__galery {
                max-width: 1000px;
                margin: 27px auto;

                & > div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 0 -5px;

                    @media screen and (max-width: 1145px) {
                        justify-content: center;
                    }
                }
                & > div > img {
                    width: 240px;
                    margin: 5px;
                    border-radius: 16px;
                }
            }
        }
    }

    &__menu-block {
        position: relative;
        background: #FFFFFF;
        padding: 0 40px;

        @media screen and (max-width: 750px) {
            padding: 0 20px;
        }

        &__content {
            max-width: 1240px;
            margin: 0 auto;
            border: 3px solid #FFFFFF80;
            border-top: 0;
            border-bottom: 0;
            padding: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: 750px) {
                padding: 16px;
            }

            &__list {
                max-width: 1000px;
                margin: 0 auto;
                display: grid;
                grid-template-columns: repeat(4, 240px);
                gap: 10px;

                @media screen and (max-width: 1100px) {
                    grid-template-columns: repeat(3, 240px);
                }
                @media screen and (max-width: 850px) {
                    grid-template-columns: repeat(2, 240px);
                }
                @media screen and (max-width: 550px) {
                    grid-template-columns: repeat(1, 240px);
                }

                &__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &__image {
                        & > img {
                            width: 100%;
                            border-radius: 16px;
                        }
                    }
                    &__title {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: var(--site-scheme-color-2);
                        padding: 10px 0;
                    }
                    &__props {
                        display: flex;
                        justify-content: center;

                        &__item {
                            padding: 0 5px;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #BDBDBD;
                        }
                    }
                }
            }
            &__buttons {
            }
        }
    }

    &__news-block {
        position: relative;
        background: var(--site-scheme-color-3);
        padding: 0 40px;

        @media screen and (max-width: 750px) {
            padding: 0 20px;
        }

        &__content {
            max-width: 1240px;
            margin: 0 auto;
            border: 3px solid #FFFFFF80;
            border-top: 0;
            border-bottom: 0;
            padding: 32px;

            @media screen and (max-width: 750px) {
                padding: 16px;
            }

            &__title {
                font-size: 40px;
                font-weight: 500;
                line-height: 49px;
                letter-spacing: 0em;
                text-align: center;
                color: var(--site-scheme-color-1);
            }
            &__list {
                max-width: 1000px;
                margin: 16px auto;
                display: flex;
                justify-content: center;

                & > div {
                    margin: 0 -16px;
                    display: flex;
                    justify-content: space-between;

                    @media screen and (max-width: 830px) {
                        flex-direction: column;
                    }
                }

                &__item {
                    width: 33%;
                    display: flex;
                    justify-content: flex-start;
                    padding: 16px;

                    @media screen and (max-width: 830px) {
                        width: 100%;
                    }

                    &__image {
                        width: 40%;

                        @media screen and (max-width: 830px) {
                            width: 30%;
                        }
                        
                        & > img {
                            width: 100%;
                            border-radius: 16px;
                        }
                    }
                    &__text {
                        width: 60%;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15px;
                        letter-spacing: 0em;
                        padding-left: 10px;
                        color: #E0E0E0;

                        @media screen and (max-width: 830px) {
                            width: 70%;
                            font-size: 14px;
                            line-height: 16px;
                        }

                        &__title {
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: var(--site-scheme-color-4);

                            @media screen and (max-width: 830px) {
                                font-size: 24px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }
            &__buttons {    
                &__empty {
                    border: 3px solid var(--site-scheme-color-4);
                    color: var(--site-scheme-color-4);
                }
                &__fill {
                    background: var(--site-scheme-color-4);
                    border: 3px solid var(--site-scheme-color-4);
                }
            }    
        }
    }

    &__banquets-block {
        position: relative;
        background: var(--site-scheme-color-1);
        padding: 0 40px;
        z-index: 110;

        @media screen and (max-width: 750px) {
            padding: 0 20px;
        }

        &__content {
            max-width: 1240px;
            margin: 0 auto;
            border: 3px solid #FFFFFF80;
            border-top: 0;
            border-bottom: 0;
            padding: 32px;

            @media screen and (max-width: 750px) {
                padding: 16px;
            }

            &__block {
                position: relative;
                display: flex;
                justify-content: space-between;

                @media screen and (max-width: 750px) {
                    flex-direction: column-reverse;
                }

                & > div {
                    width: 50%;

                    @media screen and (max-width: 750px) {
                        width: 100%;
                    }
                }
            }

            &__title {
                font-size: 40px;
                font-weight: 500;
                line-height: 49px;
                letter-spacing: 0em;
                text-align: left;
                color: var(--site-scheme-color-2);
                padding-bottom: 15px;
            }
            &__text {
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                color: #E0E0E0;
                padding-right: 32px;

                @media screen and (max-width: 750px) {
                    font-size: 14px;
                    line-height: 18px;
                    padding-top: 16px;
                    padding-right: 0;
                }
            }
            &__image {
                width: 50%;

                @media screen and (max-width: 750px) {
                    width: 100%;
                }

                & > img {
                    width: 100%;
                    border-radius: 16px;
                }
            }

            &__buttons {    
                &__empty {
                    border: 3px solid var(--site-scheme-color-2);
                    color: var(--site-scheme-color-2);
                }
                &__fill {
                    background: var(--site-scheme-color-2);
                    border: 3px solid var(--site-scheme-color-2);
                }
            }   
        }
    }

    &__footer-block {
        position: relative;
        width: 100%;
        background: var(--site-scheme-color-5);
        padding: 0 40px 40px 40px;

        @media screen and (max-width: 750px) {
            padding: 0 20px 20px 20px;
        }

        &__content {
            width: 100%;
            max-width: 1240px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0 auto;
            border: 3px solid #FFFFFF80;
            border-radius: 0 0 16px 16px;
            border-top: 0;
            padding: 32px;

            @media screen and (max-width: 750px) {
                padding: 20px;
            }

            &__logo {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 22px;

                & > img {
                    max-width: 60px;
                    max-height: 60px;
                    padding-right: 15px;
                }
            }

            &__block {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                &__row {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex: 1;
                }
                &__title {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: var(--site-scheme-color-2);
                    padding-right: 15px;
                }
                &__entities {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    &__entity {
                        padding-top: 15px;
                        padding-right: 15px;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #F2F2F2;
                    }
                }
            }
            // &__menu {
            //     display: flex;
            //     justify-content: flex-end;

            //     &__item {
            //         font-size: 20px;
            //         font-weight: 700;
            //         line-height: 24px;
            //         letter-spacing: 0em;
            //         text-align: left;
            //         color: #FFFFFF;
            //         margin-left: 20px;
            //     }
            // }
        }
    }
}
</style>
